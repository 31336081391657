








import { Vue, Component, Watch } from "vue-property-decorator";
import { RouteRecord } from 'vue-router';

@Component
export default class extends Vue {
    list: RouteRecord[] = [];

    @Watch('$route')
    getRouterList() {
        this.list = this.$route.matched.filter((item) => {
            return item.meta && item.meta.title;
        });
    }

    get id() {
        return this.$route.query?.id;
    }

    created() {
        this.getRouterList();
    }
}
