





























































import { Vue, Component, Watch } from "vue-property-decorator";

import Tab from "./tab.vue";
import RView from "@/components/routerView/index.vue";
import Bread from "./bread.vue";
import { UserModule } from "@/model/user";
// import itemVue from "../rightMenu/item.vue";
@Component({
  components: {
    Tab,
    RView,
    Bread,
  },
})
export default class extends Vue {
  logout() {
    UserModule.logout();
    this.$router.push("/login");
  }

  reset() {
    this.$router.push("/");
    this.$nextTick(() => {
      location.reload();
    });
  }

  get username() {
    return UserModule.user_info.username;
  }

  created() {}

  destroyed() {}
}
