









































import { UserModule } from "@/model/user";
import { Vue, Component } from "vue-property-decorator";
@Component
export default class extends Vue {
  get defaultActive() {
    const currentRoute = this.$route;
    let defaultActive = currentRoute.path.toString();
    return defaultActive;
  }

  get auth() {
    return UserModule.auth.map((e: any) => {
      return e.id;
    });
  }

  roles(role: []) {
    return role.some((e: number) => {
      return this.auth.includes(e);
    });
  }
}
